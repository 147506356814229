import './scss/style.scss'

import designFunc from './ts/designModal'
import humbarger from './ts/humbarger'
import menuSwitch from './ts/menuSwitch'
import modalFunc from './ts/topModal'
import spHeader from './ts/sp-header'
import swiperCal from './ts/calendar'
import travelplanOmutaYanagawa from './ts/travelplanOmutaYanagawa'

// windowに何かつっこんだ場合の型宣言です
declare global {
  interface Window {
    get_theme_file_url: String
  }
}

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

designFunc()
humbarger()
modalFunc()
spHeader()
swiperCal()
travelplanOmutaYanagawa()

const developUtl = 'http://railkitchen.sandbox.freemethod.co.jp/'
const developUtl_cuisine = 'http://railkitchen.sandbox.freemethod.co.jp/#cuisine'
const developUtl_timetable = 'http://railkitchen.sandbox.freemethod.co.jp/#timetable'
const develop2Utl = 'https://railkitchen.sandbox2.any-sense.jp/'
const develop2Utl_cuisine = 'https://railkitchen.sandbox2.any-sense.jp/#cuisine'
const develop2Utl_timetable = 'https://railkitchen.sandbox2.any-sense.jp/#timetable'
const develop3Utl = 'http://210.128.123.179/railkitchen/'
const develop3Utl_cuisine = 'http://210.128.123.179/railkitchen/#cuisine'
const develop3Utl_timetable = 'http://210.128.123.179/railkitchen/#timetable'
const productsUtl = 'https://www.railkitchen.jp/'
const productsUtl_cuisine = 'https://www.railkitchen.jp/#cuisine'
const productsUtl_timetable = 'https://www.railkitchen.jp/#timetable'
if (
  window.location.href === developUtl ||
  window.location.href === developUtl_cuisine ||
  window.location.href === developUtl_timetable ||
  window.location.href === develop2Utl ||
  window.location.href === develop2Utl_cuisine ||
  window.location.href === develop2Utl_timetable ||
  window.location.href === develop3Utl ||
  window.location.href === develop3Utl_cuisine ||
  window.location.href === develop3Utl_timetable ||
  window.location.href === productsUtl ||
  window.location.href === productsUtl_cuisine ||
  window.location.href === productsUtl_timetable
) {
  menuSwitch()
}

const app = document.querySelector<HTMLDivElement>('#app')!

if (app) {
  app.innerHTML = `<h1>Hello Vite!</h1>`
}
